import { getLaunchDarklyClientId } from '@hopin-team/dashboard-constants';
import * as LDClient from 'launchdarkly-js-client-sdk';
import { eventChannel } from 'redux-saga';
import { select } from 'typed-redux-saga';

import { appLogger } from '../logger';
import { getCurrentPaymentPlan, getEvent, getUserId } from '../store/selectors';
import { FLAG_DEFAULTS } from './flags';

export function* connectToLD() {
  try {
    const event = yield* select(getEvent);
    const userId = yield* select(getUserId);

    const eventSlug = event?.slug;
    const organizationId = event?.organization.externalId;
    const organizationPaymentPlan = (yield* select(getCurrentPaymentPlan))
      ?.name;
    const eventId = event?.externalId;
    const eventCreatedDatetime = event?.createdAt
      ? new Date(event.createdAt).getTime()
      : undefined;
    const ldOptions = {
      ...(userId && { userId }),
      ...(eventId && { eventId }),
      ...(eventSlug && { eventSlug }),
      ...(organizationId && {
        organizationExternalId: organizationId,
        organizationPaymentPlan,
      }),
      ...(eventCreatedDatetime && { eventCreatedDatetime }),
    };

    const ldClient = LDClient.initialize(
      getLaunchDarklyClientId(),
      {
        key: 'event-dashboard-app',
        ...ldOptions,
        custom: {
          ...ldOptions,
        },
      },
      {
        diagnosticOptOut: true,
        bootstrap: FLAG_DEFAULTS,
        logger: LDClient.basicLogger({ level: 'error' }),
      },
    );

    ldClient.on('error', (error?: Error) =>
      appLogger.error('Launch darkly client encountered an error', { error }),
    );

    ldClient.on('failed', (error?: Error) => {
      appLogger.error('Unable to initialise launch darkly client', { error });
    });

    return eventChannel<LDClient.LDFlagSet>(emit => {
      ldClient.on('initialized', () => {
        emit(ldClient.allFlags());
      });

      ldClient.on('change', () => {
        emit(ldClient.allFlags());
      });

      return () => ldClient.close();
    });
  } catch (error) {
    appLogger.error(`event dashboard: unable to connect to launch darkly`, {
      error,
    });
  }
}
